import React, { useCallback, useEffect, useMemo } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import cn from "classnames";
import axios from "axios";

import HomeContainer from "../home";
import SavedContainer from "../saved";
import FeedContainer from "../feed";
import CallHistoryContainer from "../callHistory";
import NotificationsContainer from "../notifications";
import Payments from "../payments";
import ManageNotification from "../manageNotification";
import TeamsContainer from "../teams";
import AccessContainer from "../access";
import Header from "../../components/header";
import PayPerLeadContainer from "../payPerLead";
import ReferralsContainer from "../referrals";
import { PaymentError } from "../../components/common/paymentError";
import styles from "./mainRoutes.module.css";
import PayPerLeadDetailContainer from "../payPerLeadDetail";
import PerformanceContainer from "../performance";
import Account from "../account";
import ListingsContainer from "../listings";
import ListingDetailContainer from "../listingDetail";
import NotFoundContainer from "../404";
import QuestionsAnswersContainer from "../questionsAnswers";
import QuestionContainer from "../question";
import ReferralRequestDetailContainer from "../referralRequestDetail";
import Messages from "../messages";

/* hide due this page https://heritageweb.atlassian.net/browse/GROWTH-2086
import CannyContainer from "../canny";
*/

import Footer from "../../components/footer";
import MainSidebar from "../../components/mainSidebar";
import { BannerReferralRequests } from "../../components/bannerReferralRequests";
import { Importing } from "../../components/importing";
import { ImportingSuccess } from "../../components/importingSuccess";
import VerificationEmailFlow from "../../components/verificationEmailFlow";
import { AddNewListing } from "../../components/listings/components/modals/addFreeListing";
import { actions as referralRequestsActions } from "../../actions/referralRequests";
import { actions as homeActions } from "../../actions/home";
import { getQueryParams } from "../../helpers";
import { actions as authActions } from "../../actions/auth";
import { actions as teamActions } from "../../actions/teams";
import { actions as profileActions } from "../../actions/account/profile";
import { actions as mainActions } from "../../actions/main";

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.status === 401 && !error.response.config.url.includes("/login")) {
      const currentUrl = window.location.href;
      const urlParams = new URLSearchParams(window.location.search);
      if (!urlParams.get("isAuthenticationError")) {
        urlParams.set("isAuthenticationError", "true");
      }
      const newUrl = `${currentUrl.split("?")[0]}?${urlParams.toString()}`;
      window.history.replaceState({ path: newUrl }, "", newUrl);
    }

    // Handle other types of errors here
    return Promise.reject(error);
  }
);

const MainRoutes = ({
  countries,
  client,
  getMainInfo,
  location,
  states,
  getImportReady,
  modalsReferralRequest,
  importReady,
  main,
  toggleNewListing,
  getAddNewListingData,
  // eslint-disable-next-line
  keycloak
}) => {
  const dispatch = useDispatch();
  const isMessages = location.pathname === "/inbox";
  const isHome = location.pathname === "/";
  const isQA = location.pathname.includes("question-answer");
  const isReferralRequests = location.pathname.includes("referral-requests");
  const isPaidLeads = location.pathname.includes("paid-leads");
  const pageGrey = isMessages ? styles.pageGrey : "";
  const history = useHistory();
  const queryParams = useMemo(() => getQueryParams(window.location.search), [window.location.search]);
  const setLoggedIn = useCallback(isActive => dispatch(authActions.setLoggedIn(isActive)), [dispatch]);
  const getProfile = useCallback(() => dispatch(profileActions.getProfile()), [dispatch]);
  const getProfileImage = useCallback(() => dispatch(mainActions.getProfileImage()), [dispatch]);
  const setCurrentTeam = useCallback(team => dispatch(teamActions.setCurrentTeam(team)), [dispatch]);
  const { profile } = useSelector(state => state.account);
  const { currentTeam } = useSelector(state => state.teams);

  useEffect(() => {
    getImportReady();
    getAddNewListingData(!isHome);
    getProfileImage();
  }, []);

  useEffect(() => {
    if (!countries?.length) {
      getMainInfo();
    }
  }, [getMainInfo, countries]);

  useEffect(() => {
    if (queryParams?.isAuthenticationError) {
      setLoggedIn(false);
      keycloak.logout({ redirectUri: `${window.location.origin}/login?isAuthenticationError=true` }).then(() => {
        history.push("/login");
      });
    }
  }, [queryParams.isAuthenticationError]);

  useEffect(() => {
    if (!profile?.length) {
      getProfile();
    }

    if (queryParams.user_id && !currentTeam.value) {
      setCurrentTeam({ value: queryParams.user_id });
    }
  }, []);

  const {
    globalInfo: {
      listings_count: listingsCount,
      referral_requests_pending_import: referralRequestsPendingImport,
      referral_requests_count: referralRequestsCount,
      new_messages: newMessages,
      unread_messages: unreadMessages
    },
    isActiveNewListing,
    listingModalListingType
  } = main;

  const isShowOnlyListingBanner =
    String(unreadMessages) === "0" &&
    String(listingsCount) === "0" &&
    String(referralRequestsPendingImport) === "0" &&
    String(referralRequestsCount) === "0" &&
    String(newMessages) === "0";

  const isShowFooter = (isHome && isShowOnlyListingBanner) || isMessages;
  const isShowBannerReferralRequests = isMessages || isQA || isReferralRequests || isPaidLeads;

  const closeNewListing = () => {
    toggleNewListing(false);
  };

  return (
    <>
      <div className={cn(styles.page, pageGrey)}>
        <MainSidebar keycloak={keycloak} />
        <div className={styles.content}>
          {isShowBannerReferralRequests && <BannerReferralRequests importReady={importReady} />}

          <VerificationEmailFlow />
          <Header keycloak={keycloak} />
          <PaymentError />
          {modalsReferralRequest.importing && <Importing />}
          {modalsReferralRequest.importingSuccess && <ImportingSuccess />}
          <Switch>
            <Route exact path="/" render={() => <FeedContainer />} />
            <Route exact path="/home-archive" render={() => <HomeContainer countries={countries} states={states} />} />
            <Route exact path="/saved" render={() => <SavedContainer />} />
            <Route exact path="/inbox" render={() => <Messages />} />

            <Route
              exact
              path={["/listings", "/my-events", "/my-jobs", "/my-organizations"]}
              render={() => <ListingsContainer countries={countries} />}
            />
            <Route exact path="/call-history" render={() => <CallHistoryContainer />} />
            <Route exact path="/paid-leads" render={() => <PayPerLeadContainer />} />
            <Route exact path="/referrals" render={() => <ReferralsContainer />} />
            <Route exact path="/paid-leads/:id" render={props => <PayPerLeadDetailContainer match={props.match} />} />
            <Route exact path="/notifications" render={() => <NotificationsContainer />} />
            <Route exact path="/manage-notification" render={() => <ManageNotification />} />
            <Route exact path="/account" render={() => <Account keycloak={keycloak} countries={countries} />} />
            <Route exact path="/payments" render={() => <Payments countries={countries} keycloak={keycloak} />} />
            <Route exact path="/teams" render={() => <TeamsContainer />} />
            <Route exact path="/access" render={() => <AccessContainer />} />
            <Route exact path="/question-answer" render={() => <QuestionsAnswersContainer />} />
            <Route exact path="/question-answer/:id" render={() => <QuestionContainer />} />
            <Route exact path="/analytics" render={props => <PerformanceContainer {...props} />} />

            <Route exact path="/referral-requests/:id" render={() => <ReferralRequestDetailContainer client={client} />} />

            <Route
              exact
              path={["/listings/:id", "/my-events/:id", "/my-jobs/:id", "/my-organizations/:id", "/profile"]}
              render={props => <ListingDetailContainer utilityCountries={countries} utilityStates={states} match={props.match} />}
            />

            {/* hide due this page https://heritageweb.atlassian.net/browse/GROWTH-2086
              <Route exact path="/product-roadmap" render={() => <CannyContainer />} />
            */}
            <Route path="/404" component={NotFoundContainer} />
            <Route component={NotFoundContainer} />
          </Switch>
          {isActiveNewListing && (
            <AddNewListing
              listingModalListingType={listingModalListingType}
              closeModal={closeNewListing}
              clearAllModals={closeNewListing}
            />
          )}
        </div>
      </div>
      {!isShowFooter && <Footer />}
    </>
  );
};
const mapDispatchToProps = dispatch => {
  return {
    getMainInfo: () => dispatch(mainActions.getMainInfo()),
    toggleNewListing: () => dispatch(mainActions.toggleNewListing()),
    getImportReady: () => dispatch(referralRequestsActions.getImportReady()),
    getAddNewListingData: isModal => dispatch(homeActions.getAddNewListingData(isModal))
  };
};

const mapStateToProps = ({ main, referralRequests }) => {
  return {
    client: main.client,
    countries: main.countries,
    states: main.states,
    main,
    importReady: referralRequests.importReady,
    modalsReferralRequest: referralRequests.modals
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MainRoutes);
