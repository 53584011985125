import React from "react";
import { useTranslation } from "react-i18next";

import modalStyles from "../../../../common/modal/modal.module.css";
import { Modal } from "../../../../common/modal";
import ModalControl from "../../../../modalControl";
import { getInitialsListing } from "../../../../../helpers";
import { OverlayPreloader } from "../../../../common/preloader";
import styles from "./delete.module.css";

export const Delete = ({ closeModal, clearAllModals, itemToEdit, handleDelete, preloadersDeleteMembership, handlePause }) => {
  const { t } = useTranslation();

  const onExit = () => {
    closeModal();
  };

  const onConfirm = () => {
    handleDelete(itemToEdit.listing_id, { location: { pathname: `/my-${itemToEdit._profession.toLowerCase()}` } });
  };

  const handleClickPause = () => {
    onExit();
    handlePause();
  };

  return (
    <Modal closeModal={closeModal} classNameMain={styles.paymentOkMain} clearAllModals={clearAllModals}>
      {preloadersDeleteMembership && <OverlayPreloader overlayClassName="sectionOverlayModal zIndex4" spinnerClassName="zIndex4" />}
      <section>
        <div className={modalStyles.pointer}>{t("dashboard_listings_table_delete")}</div>
        <div className={styles.popupHeading}>Delete Your Listing</div>
      </section>
      <div className={styles.description}>
        Once your listing is deleted you will not be able to recover it any longer. Please consider{" "}
        <button type="button" onClick={handleClickPause}>
          pausing{" "}
        </button>{" "}
        your listing instead.
      </div>
      <p className={styles.deleteDescription}>Delete the listing below</p>
      <div className={styles.listing}>
        <div className={styles.listingInitials}>{getInitialsListing(itemToEdit)}</div>
        <div>
          <p className={styles.listingType}>{itemToEdit.product_name}</p>
          {itemToEdit.attorney && <p className={styles.listingName}>{itemToEdit.attorney}</p>}
        </div>
      </div>
      <ModalControl onExit={onExit} onConfirm={onConfirm}>
        {t("dashboard_listings_table_delete")}
      </ModalControl>
    </Modal>
  );
};
