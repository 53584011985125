import React from "react";
import format from "date-fns/format";
import { useTranslation } from "react-i18next";

import { Modal } from "../../../../common/modal";
import modalStyles from "../../../../common/modal/modal.module.css";
import ModalControl from "../../../../modalControl";
import { getInitialsListing, renderBrandIcon } from "../../../../../helpers";
import { OverlayPreloader } from "../../../../common/preloader";
import styles from "./downgrade.module.css";

export const Downgrade = ({ closeModal, clearAllModals, itemToEdit, downgradeMembership, preloadersDowngradeMembership }) => {
  const { t } = useTranslation();

  const {
    card_last4: last4,
    card_name: cardName,
    terms,
    listing_id: listingId,
    card_brand: brand,
    product_name: productName,
    expire_date: expireDate = "",
    attorney,
    _profession: listingType
  } = itemToEdit;

  const onExit = () => {
    closeModal();
  };

  const onConfirm = () => {
    downgradeMembership(listingId, { location: { pathname: listingType.toLowerCase() } });
  };

  return (
    <Modal closeModal={closeModal} classNameMain={styles.paymentOkMain} clearAllModals={clearAllModals}>
      {preloadersDowngradeMembership && <OverlayPreloader overlayClassName="sectionOverlayModal zIndex4" spinnerClassName="zIndex4" />}
      <section>
        <div className={modalStyles.pointer}>DOWNGRADE LISTING</div>
        <div className={styles.popupHeading}>Confirm Your Downgrade</div>
      </section>
      {expireDate && (
        <div className={styles.description}>
          Your listing will continue to be published as a sponsor listing until your current plan ends on{" "}
          {format(new Date(expireDate), "MM/dd/yyyy")} after which time your listing will be downgraded and continue to be published as a
          free listing.
        </div>
      )}
      <p className={styles.deleteDescription}>Downgrade the listing below to a free listing</p>
      <div className={styles.listing}>
        <div className={styles.listingInitials}>{getInitialsListing(itemToEdit)}</div>
        <div>
          <p className={styles.listingType}>{productName}</p>
          {attorney && <p className={styles.listingName}>{attorney}</p>}
        </div>
      </div>

      <div className={styles.plan}>
        <div className={styles.planContent}>
          <p className={styles.planTitle}>{t("dashboard_listings_modal_upgrade_confirm_h3")}</p>
        </div>
        <p className={styles.planName}>{`${productName} - ${terms}`}</p>
        {expireDate && <p className={styles.planName}>{`Expires ${format(new Date(expireDate), "MM/dd/yyyy")}`}</p>}
      </div>

      {(last4 || cardName) && (
        <div className={styles.method}>
          <div className={styles.methodContent}>
            <p className={styles.methodTitle}>{t("dashboard_listings_modal_upgrade_confirm_h4")}</p>
          </div>
          <div className={styles.card}>
            {brand && (
              <div className={styles.cardImg}>
                <img src={renderBrandIcon(brand)} alt={`${brand} card`} />
              </div>
            )}

            <div>
              {last4 && <span className={styles.cardNumber}>**** - **** - **** - {last4}</span>}
              {cardName && <p className={styles.cardDefault}>{cardName}</p>}
            </div>
          </div>
        </div>
      )}
      <ModalControl onExit={onExit} onConfirm={onConfirm}>
        Confirm Downgrade
      </ModalControl>
    </Modal>
  );
};
