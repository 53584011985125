import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { PopupActions } from "../../../common/popupActions";
import { ReactComponent as EditIcon } from "../../../../media/icons/edit-listing-icon.svg";
import { ReactComponent as OptionIcon } from "../../../../media/icons/options.svg";
import { modals as modalsConstant, statuses } from "../../../../constants/listings";
import { Button } from "../../../common/button";
import styles from "../../listings.module.css";

export const ControlMenu = ({
  listing_id: listingId,
  isDots,
  status,
  className,
  handleUpgrade,
  isSponsor,
  paused,
  promptClassName,
  openModal,
  index,
  canceledDate,
  publishStatus,
  team,
  listingType
}) => {
  const { t } = useTranslation();
  const [actionPrompt, setActionPrompt] = useState(false);
  const isNeverPublished = status === statuses.neverPublished;
  const listingsTypes = ["/my-events", "/my-jobs", "/my-organizations"];
  const isEvensOrJobsPage = listingsTypes.some(item => item.includes(listingType.toLowerCase()));
  const pathname = isEvensOrJobsPage ? `/my-${listingType.toLowerCase()}` : "/listings";
  const isActiveStatus = publishStatus === statuses.active;

  const closePrompt = () => {
    setActionPrompt(false);
  };

  return (
    <PopupActions
      className={className}
      setActionPrompt={closePrompt}
      promptRoot={isDots ? <OptionIcon /> : <EditIcon />}
      promptRootClass={styles.editButton}
      promptClass={cn(styles.prompt, promptClassName)}
    >
      {!actionPrompt && (
        <React.Fragment>
          <Link className={styles.popupLink} to={`${pathname}/${listingId}${team?.value ? `?user_id=${team.value}` : ""}`}>
            {t("dashboard_listings_table_edit")}
          </Link>

          {isSponsor && !canceledDate && !isNeverPublished && (
            <Button className={styles.popupLink} onClick={() => openModal(modalsConstant.downgrade, index)}>
              Downgrade Listing
            </Button>
          )}

          {!isSponsor && (
            <Button className={styles.popupLink} onClick={handleUpgrade}>
              {t("dashboard_listings_modal_upgrade_top_title")}
            </Button>
          )}

          {isActiveStatus || isSponsor || String(paused) === "1" ? (
            <>
              {String(paused) === "1" ? (
                <Button className={styles.popupLink} onClick={() => openModal(modalsConstant.unpause, index)}>
                  {t("dashboard_single_listing_unpause")}
                </Button>
              ) : (
                <Button className={styles.popupLink} onClick={() => openModal(modalsConstant.pause, index)}>
                  {t("dashboard_single_listing_pause")}
                </Button>
              )}
            </>
          ) : null}

          <Button className={styles.popupLink} onClick={() => openModal(modalsConstant.delete, index)}>
            {t("dashboard_listings_table_delete")}
          </Button>
        </React.Fragment>
      )}
    </PopupActions>
  );
};
