import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";

import { useLoadAllUseEffects } from "./hooks";
import styles from "./payPerLead.module.css";
import { actions as referralRequestDetailActions } from "../../actions/referralRequestDetail";
import { Search } from "./search";
import { actions as referralRequestsActions } from "../../actions/referralRequests";
// import Tabs from "./tabs";
import SubTabs from "./subTabs";
import Filters from "./filters";
import List from "./list";
import { OverlayPreloader } from "../common/preloader";
import LeadAndReferralsPagination from "./leadAndReferralsPagination";
import Modals from "./modals";
import SelectTeam from "../selectTeam";
import SelectInput from "../common/selectInput";

const PayPerLead = () => {
  const dispatch = useDispatch();
  const [activeSort, setActiveSort] = useState("");
  const { t } = useTranslation();
  const history = useHistory();
  const { location } = history;

  const searchParams = queryString.parse(location.search);
  const options = [
    { value: "desc", label: t("dashboard_ppl_sortdropdown_recent") },
    { value: "asc", label: t("dashboard_ppl_sortdropdown_oldest") }
  ];

  const { modals, lead, coupon, success, error: errorBuyLead } = useSelector(state => state.payPerLeadDetail);

  const getCases = useCallback(data => dispatch(referralRequestsActions.getCases(data)), [dispatch]);

  const handleRemoveReferrals = useCallback(
    leadId => dispatch(referralRequestDetailActions.handleRemove(leadId, history, true, searchParams)),
    [dispatch, history, searchParams]
  );

  const { filters, loading, leads, pagination, tabsCounter } = useSelector(state => state.payPerLead);
  const { directories, practices } = filters;
  const {
    filters: filtersReferrals,
    pagination: paginationReferrals,
    preloaders: preloadersReferrals
    // isShowReferralsTab
    // cases: referrals,
    // updateReferralRequest,
    // importReady,
  } = useSelector(state => state.referralRequests);

  const { directories: directoriesReferrals, practices: practicesReferrals } = filtersReferrals;
  const [leadsList, setLeadsList] = useState([]);
  const [referralsList, setReferralsList] = useState([]);
  const [search, setSearch] = useState("");
  // const [searchReferrals, setSearchReferrals] = useState("");
  const [filtrationByDate, setFiltrationByDay] = useState("");
  const [perPage, setPerPage] = useState("");
  const [perPageLead, setPerPageLead] = useState("");
  const [listActivePractice, setListActivePractice] = useState([]);
  const [listActiveNetwork, setListActiveNetwork] = useState([]);
  const [notification, setNotification] = useState(null);
  const [error, setError] = useState(null);
  const isReferrals = searchParams.tab?.includes("referrals");
  const currentPractices = isReferrals ? practicesReferrals : practices;
  const currentDirectories = isReferrals ? directoriesReferrals : directories;
  const [activeFilter, setActiveFilter] = useState(false);
  const [team, setTeam] = useState({ value: "", label: "Unselect " });

  useLoadAllUseEffects({
    setListActiveNetwork,
    setListActivePractice,
    listActiveNetwork,
    setLeadsList,
    setSearch,
    setNotification,
    setError,
    listActivePractice,
    filtrationByDate,
    setReferralsList,
    activeSort,
    searchValue: search,
    perPage,
    perPageLead,
    team
  });

  const handleFiltrationSortReferrals = sort => {
    setActiveSort(sort);
    getCases({
      sort: sort.value,
      dirId: listActiveNetwork,
      practiceId: listActivePractice,
      type: searchParams.tab,
      page: searchParams.page,
      search: ""
      // search: searchReferrals
    });
  };
  /*
  const handleFiltrationSearchReferrals = searchValue => {
    setSearchReferrals(searchValue);
    getCases({
      sort: activeSort?.value,
      dirId: listActiveNetwork,
      practiceId: listActivePractice,
      type: searchParams.tab,
      page: searchParams.page,
      search: searchValue,
      isSelectedFilter: true
    });
  };
  */

  const handleFiltrationSearchLeads = searchValue => {
    setSearch(searchValue);
  };

  const historyPush = () => {
    history.push(`/paid-leads?${queryString.stringify({ ...searchParams, page: 0 })}`);
  };

  const onDateFiltrationLead = item => {
    if (String(searchParams.page) !== "0") {
      historyPush();
    }

    setFiltrationByDay(item);
  };

  return (
    <div className={styles.wrapper}>
      {(loading || preloadersReferrals.preloaderCase) && <OverlayPreloader />}
      <h1 className={styles.mainTitle}>Business referrals</h1>

      <Search handleFiltrationSearch={handleFiltrationSearchLeads} search={search} isReferrals={false}>
        <SelectTeam handleChange={setTeam} unselectText="My Referrals" className={styles.team} />

        <SelectInput
          classNameWrap={styles.selectWrap}
          className={styles.selectLatest}
          name="latest"
          placeholder="Most Recent"
          defaultValue={{ value: "", label: t("dashboard_ppl_sortdropdown_default") }}
          onChange={onDateFiltrationLead}
          options={options}
          value={filtrationByDate}
        />
      </Search>

      <div className={styles.container}>
        {/* Content and Filter */}
        <div className={styles.tabNavContent}>
          <div>
            {/*
            <Tabs
              setListActivePractice={setListActivePractice}
              listActivePractice={listActivePractice}
              setListActiveNetwork={setListActiveNetwork}
              listActiveNetwork={listActiveNetwork}
              tabsCounter={tabsCounter}
              tabsCounterReferrals={tabsCounterReferrals}
              setActiveFilter={setActiveFilter}
              activeFilter={activeFilter}
              isShowReferralsTab={false}
            />
            */}
            <div className={styles.flex}>
              <SubTabs tabsCounter={tabsCounter} />

              <Filters
                setActiveFilter={setActiveFilter}
                activeFilter={activeFilter}
                options={options}
                practices={currentPractices}
                listActivePractice={listActivePractice}
                setListActivePractice={setListActivePractice}
                filtersReferrals={filtersReferrals}
                filters={filters}
                filtrationByDate={filtrationByDate}
                setFiltrationByDay={setFiltrationByDay}
                searchParams={searchParams}
                directories={currentDirectories}
                listActiveNetwork={listActiveNetwork}
                setListActiveNetwork={setListActiveNetwork}
                isReferrals={isReferrals}
                handleFiltrationSortReferrals={handleFiltrationSortReferrals}
                activeSort={activeSort}
                referralsList={referralsList}
                leadsList={leadsList}
              />
            </div>

            <div>
              <List
                referralsList={referralsList}
                isReferrals={isReferrals}
                leadsList={leadsList}
                practices={practices}
                handleRemoveReferrals={handleRemoveReferrals}
                team={team}
              />

              <LeadAndReferralsPagination
                setPerPage={setPerPage}
                setPerPageLead={setPerPageLead}
                getCases={getCases}
                activeSort={activeSort}
                listActiveNetwork={listActiveNetwork}
                listActivePractice={listActivePractice}
                searchParams={searchParams}
                searchReferrals=""
                // searchReferrals={searchReferrals}
                history={history}
                pagination={pagination}
                paginationReferrals={paginationReferrals}
                leads={leads}
                referralsList={referralsList}
                isReferrals={isReferrals}
              />
            </div>
          </div>
          {/* modals and notification */}
          <Modals
            error={error}
            notification={notification}
            success={success}
            errorBuyLead={errorBuyLead}
            lead={lead}
            coupon={coupon}
            modals={modals}
            practices={practices}
          />
        </div>
      </div>
    </div>
  );
};

export default PayPerLead;
